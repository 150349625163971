import * as React from "react";

import Footer from "./Footer";
import Header from "./Header";

const Layout = (props: React.PropsWithChildren<{}>) => {
    const { children } = props;
    return (
        <>
            <Header />
            <main className="text-gray-900">
                {children}
            </main>
            <Footer />
        </>
    )
};

export default Layout;
