import { Link } from "gatsby";
import * as React from "react";

const Footer = () => {
    return (
        <footer className="container mx-auto py-16 px-3 mt-16 mb-8 text-gray-800">
            <div className="text-center text-sm">
                <Link to="/contact">Contact Us</Link> | <Link to="/about-us">About Us</Link>
            </div>
            <br />
            <div className="text-center text-sm">© Copyright 2022 Eborsoft Ltd.</div>
        </footer>
    );
};

export default Footer;
