import { Link, navigate } from "gatsby";
import * as React from "react";
import { faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../Button";
import Logo from "../../images/svg/logo.svg";

const Header = () => {
    return (
        <header className="sticky top-0 bg-white shadow z-50">
            <div className="container flex flex-col md:flex-row justify-between items-center mx-auto py-2 px-4 md:px-8">
                <div>
                    <Link to="/">
                        <img src={Logo} className="h-8 md:h-12" alt="Eborsoft logo" />
                    </Link>
                </div>
                <div className="font-topmenu flex mt-4 md:mt-0 text-sm lg:text-base">
                    <Link className="px-4" to="/">Home</Link>
                    <Link className="px-4" to="/services">Services</Link>
                    <Link className="px-4" to="/process">Process</Link>
                    <Link className="px-4" to="/about-us">About Us</Link>                    
                </div>
                <div className="hidden md:block">
                    <Button
                        onClick={() => navigate("/contact")}
                        size="lg">
                        Contact Us
                    </Button>
                </div>
            </div>
            <div className="block md:hidden absolute top-2 right-4">
                <Button size="xs" onClick={() => navigate("/contact")}>
                    <FontAwesomeIcon icon={faPhone} className="text-xl text-white" />
                </Button>
            </div>
        </header>
    );
};

export default Header;
