import clsx from "clsx";
import * as React from "react";

const sizes = {
    default: `py-3 px-8`,
    xs: `py-1 px-3`,
    sm: `py-2 px-6`,
    lg: `py-4 px-12`,
    xl: `py-5 px-16 text-lg`
};

interface Props {
    className?: string;
    size?: keyof typeof sizes;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    type?: "button" | "submit" | "reset",
    disabled?: boolean,
}

const Button = (props: React.PropsWithChildren<Props>) => {
    const {
        children,
        className,
        disabled,
        onClick,
        size = "default",
        type = "button",
    } = props;

    const baseClasses = `
        ${sizes[size]}
        bg-primary-default
        hover:bg-primary-darker
        rounded
        text-white`;

    const effectiveClasses = clsx(
        baseClasses,
        className, {
            "opacity-70": disabled
        });

    return (
        <button
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={effectiveClasses}>
            {children}
        </button>
    );
};

export default Button;
